import React, { useEffect, useState } from 'react'
import './navBar.css'
import { ReactComponent as MapIcon} from '../../assets/SVGs/MapIcon.svg';
import { useResMenuOptions } from '../menu/useMenuOptions.js';

function NavBar() {

  const { height, mb } = useResMenuOptions()

  return (
    <div className='navBar-wrapper' style={{'--h': `${height}px`, '--mb': `${mb}px`}}>
      <div id='nav-map-btn' className='button-wrapper selected'>
        <div className='icon-wrapper'>
          <MapIcon width={30} height={30}/>
        </div>
        <h1 className='nav-btn-text'>Карта</h1>
      </div>
    </div>
  )
}

export default NavBar