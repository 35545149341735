import React from 'react'
import './activityTag.css'

function ActivityTag({text, icon}) {
  return (
    <div className='activity-tag-wrapper'>
      <h1 className='activity-title'>{text}</h1>
      <div className='icon-wrapper'>
        {icon}
      </div>
    </div>
  )
}

export default ActivityTag