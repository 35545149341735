import React, { useEffect } from 'react'
import './adsBanner.css'
import { useAds } from './useAds'

function AdsBanner() {

  const { ads } = useAds()
  const {title, des, time} = ads

  
  return (
    <div className='ads-banner'>
      <div className='org-icon-wrapper'>
        <div className='icon'>

        </div>
      </div>
      <div className='text-box'>
        <h1 className='ads-title'>{title}</h1>
        <h1 className='time'>{time}</h1>
        <h1 className='des'>{des}</h1>
        <div className='more-button'>
          <h1 className='text'>
            смотреть
          </h1>
        </div>
      </div>
    </div>
  )
}

export default AdsBanner