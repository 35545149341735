import React, { useEffect } from 'react'
import './stateSelector.css'

function StateSelector({strs, setState, state}) {

  useEffect(() => {

    const firstBtn = document.getElementById('selector-first')
    const firstBtnText = document.getElementById('selector-first-text')
    const secondBtn = document.getElementById('selector-second')
    const secondBtnText = document.getElementById('selector-second-text')

    switch (state) {
      case 'events':
        firstBtn.classList.add('selected')
        firstBtnText.classList.add('selected')
        secondBtn.classList.remove('selected')
        secondBtnText.classList.remove('selected')
        break;

      case 'orgs':
        firstBtn.classList.remove('selected')
        firstBtnText.classList.remove('selected')
        secondBtn.classList.add('selected')
        secondBtnText.classList.add('selected')
        break;
      default:
        break;
    }

  }, [state])

  return (
    <div className='state-selector-wrapper'>
      <div onClick={() => setState('events')} id='selector-first' className='selector-btn'>
        <h1 id='selector-first-text' className='selector-btn-text'>
          {strs[0]}
        </h1>
      </div>
      <div onClick={() => setState('orgs')} id='selector-second' className='selector-btn'>
        <h1 id='selector-second-text' className='selector-btn-text'>
          {strs[1]}
        </h1>
      </div>
    </div>
  )
}

export default StateSelector