import React, { useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import { useEvents } from "../../globalHooks/useEvents";
import ActiveIcon from '../../assets/IMGs/ActiveIcon.png'
import ClusterIcon from '../../assets/IMGs/ClusterIcon.png'

// create a bitmap images
const activeIcon = new Image()
activeIcon.src = ActiveIcon;

const clusterIcon = new Image()
clusterIcon.src = ClusterIcon;



export const useMapOptions = () => {

  const [mapHeight, setMapHeight] = useState('100vh')
  const [map, setMap] = useState()
  const { updateEvents, events } = useEvents()
  const [requestCounter, setRequestCouner] = useState(0)
  const [currentCenter, setCurrentCenter] = useState()
  const [prevCenter, setPrevCenter] = useState()
  const [move, setMove] = useState()

  // map fucntions 
  const renderDotsOfEvents = (points, map) => { 
    const geojson = {
      type: 'FeatureCollection',
      features: [
  
      ]
    };

    for (const dot of points) {
      const newMonument = 
      {
        type: "Feature",
        properties: {
          id: dot.id,
          time: dot.timestamp_start,
          paid: dot.paid,
          type: 'active',
        },
        geometry: {
          type: "Point",
          coordinates: [dot.lng, dot.lat, 0]
        }
      }
      geojson.features.push(newMonument)
    }

    addMonumentsToMap(map, geojson)

  }

  const getVisibleMapViewport = () => {
    const corners = map.transform.cameraFrustum.bounds.getCorners()
    const center = new mapboxgl.MercatorCoordinate(map.transform.cameraFrustum.bounds.center[0], map.transform.cameraFrustum.bounds.center[1], map.transform.cameraFrustum.bounds.center[2]).toLngLat().toArray()
    const lnglatCorners = []
    const requestDate = []

    for (const corner of corners) {
      const nullIsland = new mapboxgl.MercatorCoordinate(corner[0], corner[1], corner[2]).toLngLat().toArray();
      lnglatCorners.push(nullIsland)
    }

    for (let i = 0; i <= 3; i++) {
      requestDate.push(lnglatCorners[i])
    }

    return {
      lngMax: requestDate[1][0] + 0.75,
      lngMin: requestDate[0][0] - 0.75,
      latMax: requestDate[1][1] + 0.75,
      latMin: requestDate[2][1] - 0.75,
      center,
    }
  }

  const get = () => {
    updateEvents(getVisibleMapViewport())
  }

  const checkRequestData = (prev, current) => {

    const center = {
      lng: getVisibleMapViewport().center[0] - 0.2,
      lat: getVisibleMapViewport().center[1] - 0.2,
    }
    

    if (prev === undefined) {
      get()
      setPrevCenter(getVisibleMapViewport())
      return
    } 

    let res = false
    if (prev.lngMax <  center.lng || prev.lngMin > center.lng) {
      res = true
    }

    if (prev.latMax < center.lat || prev.latMin > center.lat) {
      res = true
    }

    if (res) {
      updateEvents(getVisibleMapViewport())
      setPrevCenter(getVisibleMapViewport())
    }
  }

  // add styles to layers
  const addMonumentsToMap = (map, data) => {

    map.addImage('active', activeIcon);
    map.addImage('cluster', clusterIcon)

    if (map.getSource('earthquakes') === undefined) {
      map.addSource('earthquakes', {
        type: 'geojson',
        data: data,
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: 50
      });

      map.addLayer({
        id: 'clusters',
        type: 'symbol',
        source: 'earthquakes',
        filter: ['has', 'point_count'],
        layout: {
          'icon-image': 'cluster',
          'icon-size': 0.1,
          'text-translate': ['0', '100'],
          'text-field': ['get', 'point_count_abbreviated'],
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        }
      });

      map.addLayer({
        id: 'unclustered-point',
        source: 'earthquakes',
        type: 'symbol',
        filter: ['!', ['has', 'point_count']],
        layout: {
          'icon-image': ['get', 'type'],
          'icon-size': 0.1
        }
      })
      


    } else {
      map.getSource('earthquakes').setData(data)
    }

    // inspect a cluster on click
    map.on('click', 'clusters', (e) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ['clusters']
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource('earthquakes')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });

    // When a click event occurs on a feature in
    // the unclustered-point layer, open a popup at
    // the location of the feature, with
    // description HTML from its properties.
    map.on('click', 'unclustered-point', (e) => {
      const coordinates = e.features[0].geometry.coordinates.slice();
      const id = e.features[0].properties.id;
      const paid = e.features[0].properties.paid === 1 ? 'yes' : 'no';

      map.easeTo({
        center: coordinates,
        zoom: 17
      });

      // Ensure that if the map is zoomed out such that
      // multiple copies of the feature are visible, the
      // popup appears over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      new mapboxgl.Popup()
        .setLngLat(coordinates)
        .setHTML(`id: ${id}<br>paid?: ${paid}`)
        .addTo(map);
    });

    map.on('mouseenter', 'clusters', () => {
      map.getCanvas().style.cursor = 'pointer';
    });
    map.on('mouseleave', 'clusters', () => {
      map.getCanvas().style.cursor = '';
    });
  }
  
  // effects

  useEffect(() => {
    if (map === undefined) return
    map.on('load', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
    map.on('dragend', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
    map.on('zoomend', () => {
      setCurrentCenter(getVisibleMapViewport().center)
    })
  }, [map])

  useEffect(() => {
    if (currentCenter === undefined) return
    checkRequestData(prevCenter, currentCenter)
  }, [currentCenter])

  // render all map source
  useEffect(() => {
    if (events === undefined) return
    renderDotsOfEvents(events, map)
  }, [events])

  return {
    mapHeight,
    setMap,
    get,
    events,
  }
}