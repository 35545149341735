import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './imageSwiper.css'
import { useEvents } from '../../globalHooks/useEvents'
import backImage from '../../assets/IMGs/TestImg.png'
import { Autoplay, Pagination, Navigation } from 'swiper/modules';


export default function ImageSwiper() {

  const { dbevents } = useEvents()

  const pagination = {
    clickable: true,
  };

  return (
    <>
      <Swiper
        spaceBetween={50}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,

        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="image-swiper-wrpaper"
      >
        {dbevents.map((item) => {
          return (
            <SwiperSlide key={item.id} id='image-swiper'>
              <div className='swiper-slide-box' style={{backgroundImage: `url(${backImage})`, objectFit: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center', backgroundSize: '150%'}}> 
                <div className='swiper-slide-box-text'>
                  <div className='swiper-slide-box-text-wrapper'>
                    <h1>
                      {item.title}
                    </h1>
                    <h1 className='text-time'>
                      {item.time}
                    </h1>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          )
        })}
      </Swiper>
    </>
  );
}
