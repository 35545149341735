import React from 'react'
import './contactUs.css'
import { ReactComponent as CommentIcon} from '../../assets/SVGs/CommentIcon.svg';
import { ReactComponent as AddOrgIcon} from '../../assets/SVGs/OrgAddIcon.svg';
import { ReactComponent as AdsIcon} from '../../assets/SVGs/AdsIcon.svg';
import { ReactComponent as ArrowRight} from '../../assets/SVGs/ArrowRightOutLine.svg';

function ContactUs() {
  return (
    <div className='contactUs-wrapper'>
      <div className='contactUs'>
        <div className='contact-icon-wrapper'>
          <CommentIcon/>
        </div>
        <div className='contact-text-wrapper'>
          <h1 className='contact-text'>Обратная связь</h1>
          <ArrowRight/>
        </div>
      </div>
      <div className='AddOrganisation'>
        <div className='contact-icon-wrapper'>
          <AddOrgIcon/>
        </div>
        <div className='contact-text-wrapper'>
          <h1 className='contact-text'>Добавить организацию</h1>
          <ArrowRight/>
        </div>
      </div>
      <div className='Ads'>
        <div className='contact-icon-wrapper'>
          <AdsIcon/>
        </div>
        <div className='contact-text-wrapper'>
          <h1 className='contact-text'>Разместить рекламу</h1>
          <ArrowRight/>
        </div>
      </div>
    </div>
  )
}

export default ContactUs