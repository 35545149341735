import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import './map.css'
import "mapbox-gl/dist/mapbox-gl.css";
import { useMapOptions } from './useMapOptions.js'

const MainMap = () => {

  const mapContainerRef = useRef();
  const mapRef = useRef();
  const { mapHeight, setMap } = useMapOptions()

  useEffect(() => {
    mapboxgl.accessToken =  process.env.REACT_APP_TOKEN;

    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      bearing: 0,
      language: 'ru-RU',
      pitch: 30,
      style: 'mapbox://styles/dmitriyv2044/cm0wfsxos00yd01qydikahqem',
      center: [37.617441, 55.750404], // starting position [lng, lat]
      zoom: 15.2, // starting zoom
    });
  
    mapRef.current = map
    setMap(map)
  }, [])


  return (
    <div>
      <div
        style={{height: mapHeight}}
        ref={mapContainerRef}
        className="map-container"
      />
    </div>
  );
};

export default MainMap;