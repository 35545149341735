import React, { useEffect, useState } from 'react'
import './eventCard.css'

function EventCard({event, type}) {

  const { title, time} = event
  const [card, setCard] = useState()




  useEffect(() => {
    switch (type) {
      case 'map-ads':
        setCard(
          <div className='event-card-wrapper'>
            <div className='box'>
              <h1 className='card-title'>{title}</h1>
              <h1 className='ads'>Реклама</h1>
            </div>
            <div className='icon-wrapper'>
              <div className='icon'>
      
              </div>
            </div>
          </div>
        )
        break;
      case 'map-friends':
        setCard(
          <div className='event-card-f-wrapper'>
            <div className='box'>
              <h1 className='card-title'>{title}</h1>
              <h1 className='ads'>{time}</h1>
            </div>
          </div>
        )
        break;
      case 'search-big':
        setCard(
          <div className='event-card-search-big'>
            <div className='box'>

            </div>
          </div>
        )
        break;
        case 'search-small':
          setCard(
            <div className='event-card-search-small'>
              <div className='box'>

              </div>
            </div>
          )
          break;
      default:
        break;
    }
  }, [])




  return (
    <>
      {card}
    </>
  )
}

export default EventCard