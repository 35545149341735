import React from 'react'
import './friendsPlan.css'
import { ReactComponent as ArrowDown} from '../../assets/SVGs/ArrowDown.svg';
import EventCard from '../eventCard/eventCard.jsx'
import { useEvents } from '../../globalHooks/useEvents.js'


function FriendsPlan() {

  const { dbevents } = useEvents()
  

  return (
    <div className='friends-plan-wrapper'>
      <div className='content'>
        <div className='daySelector'>
          <h1 className='friends-title'>У ваших друзей в планах</h1>
          <div className='dropdown'>
            <h1 className='day'>сегодня</h1>
            <ArrowDown className='arrowDown'/>
         </div>
        </div>
        <div className='cards-wrapper'>
          <EventCard event={dbevents[0]} type={'map-friends'}/>
          <EventCard event={dbevents[1]} type={'map-friends'}/>
          <EventCard event={dbevents[2]} type={'map-friends'}/>
          <h1 className='card-info'>и ещё 3</h1>
        </div>
      </div>
    </div>
  )
}

export default FriendsPlan