import { Outlet } from "react-router-dom";
import React from "react";
import './index.css'
import NavBar from "./components/navBar/navBar.jsx";

export default function MainScreenLayout({children})
{
  return(
    <div className="app-wrapper">
      <Outlet />
      <NavBar/>
    </div>
  )
}