import React from 'react'
import Map from './components/map/map.jsx'
import Menu from './components/menu/menu.jsx'
import './index.css'

function MainPage() {

  return (
    <div className='screen'>
      <Map/>
      <Menu/>
    </div>
  )
}

export default MainPage