import React, { useEffect, useState } from 'react'
import './menu.css'
import { ReactComponent as IconSearch} from '../../assets/SVGs/IconSearch.svg';
import { ReactComponent as FilterSearch} from '../../assets/SVGs/FilersIcon.svg';
import { ReactComponent as MoreIcon} from '../../assets/SVGs/MoreIcon.svg';
import { useResMenuOptions } from './useMenuOptions.js';
import ResMenuFeed from '../menuFeed/menuFeed.jsx';
import ResMenuSearch from '../menuSearch/menuSearch.jsx';


function MainMenu() {

  const {transition, dragMenuMt, pb, menuState} = useResMenuOptions()

  return (
    <div id='drag-box' className='responsive-menu' style={{'--h': `${dragMenuMt}px`, '--transition': `${transition}s`, '--pb': `${pb}px`}}>
      <div id='drag-box-header' className='header'>
        <div className='compontents-wrapper'>
          <div className='line'/>
          <div className='input-search-wrapper'>
            <IconSearch className='icon-search'/>
            <input id='input-search' placeholder='Поиск' className='input-search'/>
            <FilterSearch className='filter-search'/>
          </div>
          {menuState === 'feed' ? 
            <button className='button-slideMenu'>
              <MoreIcon/>
            </button>
            :
            <div className='search-cancel-button-wrapper'>
              <h1 className='search-cancel-button'>отмена</h1>
            </div>
          }

        </div>
      </div>
      {menuState === 'feed' ? 
        <ResMenuFeed/>
        :
        <ResMenuSearch/>
      }
    </div>
  )
}

export default MainMenu