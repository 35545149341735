import React from 'react'
import { useParams } from 'react-router-dom'
import './eventPage.css'

function EventPage() {

  const params = useParams()

  return (
    <div className='title'>
      eventID:{params.id}
    </div>
  )
}

export default EventPage