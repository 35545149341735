import axios from "axios"
import { useState } from "react"

const API_URL = 'https://api.eventracker.ru'

export const useEvents = () => {


  const [events, setEvents] = useState()

  const updateEvents = async (params) => {
    const {lngMax, lngMin, latMax, latMin} = params

    try {                       
      const response = await axios.get(`${API_URL}/events/getPoints?lat_min=${latMin}&lat_max=${latMax}&lng_min=${lngMin}&lng_max=${lngMax}`)
      setEvents(response.data)
    } catch (error) {
      console.error(error);
    }
  }

  const dbevents = [
    {
      title: 'BIG EvenTracker party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
      lat: 61.268686,
      lng: 73.409016,
    },
    {
      title: 'Old EvenTracker party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
      lat: 61.256801,
      lng: 73.437864,
    },
    {
      title: 'NO name party',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
      lat: 61.253953,
      lng: 73.433700,
    },
    {
      title: 'testlongnamingsffdsfdfsd',
      time: '9 декабря в 6:20',
      id: crypto.randomUUID(),
      lat: 61.252116,
      lng: 73.444293,
    }
  ]

  return {
    events,
    updateEvents,
    dbevents,
  }

}